import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

import Theme, { theme } from '@pagerland/themes/src/RealEstate';
import {
  Navbar,
  Welcome,
  About,
  Location,
  Exterior,
  Interior,
  Surroundings,
  Gallery,
  Contact,
  SimilarProperties,
  Footer,
} from '@pagerland/themes/src/RealEstate/containers';

import preview from '@pagerland/themes/src/RealEstate/assets/preview.jpg';

import SEO from '../components/SEO';

const Index = () => (
  <Theme>
    <Helmet>
      <link href={theme.typography.googleFont} rel="stylesheet" />
    </Helmet>
    <SEO lang={'de'} title={'Erdbau Siegl'} themeColor={theme.colors.primary} description={"Nicht verzagen Siegl fragen?"} />
    <Navbar />
    <Welcome name="" />
    <About name="about" />
    <Location name="location" />
    <Exterior name="exterior" />
    <Gallery name="gallery" />
    <Contact name="contact" />
    <Footer name="about-us" />
  </Theme>
);

export default Index;
